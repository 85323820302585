<template>
  <div class="about">
    <vxe-toolbar>
      <template #buttons>
        <vxe-button @click="getTreeExpansionEvent">获取已展开</vxe-button>
        <vxe-button @click="$refs.xTable.setAllTreeExpand(true)">展开所有</vxe-button>
        <vxe-button @click="$refs.xTable.clearTreeExpand()">关闭所有</vxe-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      resizable
      border="inner"
      ref="xTable"
      :tree-config="{childrenField: 'children',reserve:true}"
      :data="treeData"
      :row-config="{isCurrent:true}"
      @toggle-tree-expand="toggleExpandChangeEvent"
      @current-change="currentChange"
    >
      <vxe-column field="name" title="app.body.label.name" tree-node></vxe-column>
      <vxe-column field="size" title="Size"></vxe-column>
      <vxe-column field="type" title="Type"></vxe-column>
      <vxe-column field="date" title="Date"></vxe-column>
    </vxe-table>
    <div >
      <textarea class="abouttext">
        <template>
  <div class="about">
    <vxe-toolbar>
      <template #buttons>
        <vxe-button @click="getTreeExpansionEvent">获取已展开</vxe-button>
        <vxe-button @click="$refs.xTable.setAllTreeExpand(true)">展开所有</vxe-button>
        <vxe-button @click="$refs.xTable.clearTreeExpand()">关闭所有</vxe-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      resizable
      border="inner"
      ref="xTable"
      :tree-config="{childrenField: 'children',reserve:true}"
      :data="treeData"
      :row-config="{isCurrent:true}"
      @toggle-tree-expand="toggleExpandChangeEvent"
      @current-change="currentChange"
    >
      <vxe-column field="name" title="app.body.label.name" tree-node></vxe-column>
      <vxe-column field="size" title="Size"></vxe-column>
      <vxe-column field="type" title="Type"></vxe-column>
      <vxe-column field="date" title="Date"></vxe-column>
    </vxe-table>
  </div>

</template>
        <script>
import {uuid} from 'vue-uuid'
import VXETable from "vxe-table";
export default {
  data() {
    return {
      treeData: [
        {
          id: 1,
          name: '节点1',
          parentId:null,
          children: [
            {
              id: 2,
              name: '节点1-1',
              parentId:1,
              children: []
            },{
              id: 55,
              name: '节点1-3',
              parentId:1,
              children: []
            },
            // 更多子节点...
          ]
        },
        // 更多节点...
      ]
    };
  },
  methods: {
    toggleExpandChangeEvent({ row, expanded }) {
      
    },
    getTreeExpansionEvent() {
      const $table = this.$refs.xTable;
      const treeExpandRecords = $table.getTreeExpandRecords();
      VXETable.modal.alert(treeExpandRecords.length);
    },
    currentChange(
      newValue,
      oldValue,
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
      $event
    ) {
      console.log(
        newValue,
        oldValue,
        row,
        rowIndex,
        $rowIndex,
        column,
        columnIndex,
        $columnIndex,
        $event
      );
  
      const $table = this.$refs.xTable;
      console.log(
        "获取父节点：",
        $table.getParentRow(newValue.row)
      );
      let id = uuid.v1();
        newValue.row.children.push({
              id: id,
              name: '节点'+id,
              parentId:newValue.row.id,
              children: []
            })
            console.log('treeData',this.treeData)
      
      $table.reloadData(this.treeData)
    }
  }
};
</script>
      </textarea>
    </div>
  </div>

</template>
<script>
import {uuid} from 'vue-uuid'
import VXETable from "vxe-table";
export default {
  data() {
    return {
      treeData: [
        {
          id: 1,
          name: '节点1',
          parentId:null,
          children: [
            {
              id: 2,
              name: '节点1-1',
              parentId:1,
              children: []
            },{
              id: 55,
              name: '节点1-3',
              parentId:1,
              children: []
            },
            // 更多子节点...
          ]
        },
        // 更多节点...
      ]
    };
  },
  methods: {
    toggleExpandChangeEvent({ row, expanded }) {
      
    },
    getTreeExpansionEvent() {
      const $table = this.$refs.xTable;
      const treeExpandRecords = $table.getTreeExpandRecords();
      VXETable.modal.alert(treeExpandRecords.length);
    },
    currentChange(
      newValue,
      oldValue,
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
      $event
    ) {
      console.log(
        newValue,
        oldValue,
        row,
        rowIndex,
        $rowIndex,
        column,
        columnIndex,
        $columnIndex,
        $event
      );
  
      const $table = this.$refs.xTable;
      console.log(
        "获取父节点：",
        $table.getParentRow(newValue.row)
      );
      let id = uuid.v1();
        newValue.row.children.push({
              id: id,
              name: '节点'+id,
              parentId:newValue.row.id,
              children: []
            })
            console.log('treeData',this.treeData)
      
      $table.reloadData(this.treeData)
    }
  }
};
</script>
<style scoped>
.abouttext{
  width: 100%;
  height: 300px;
}
</style>